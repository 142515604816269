/**Global**/
body,
html {
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
h1,
h2,
h3,
p {
  margin: 0px;
}
.paddingZero {
  padding: 0;
}
.container {
  padding: 0 32px;
  height: 100%;
}
@media (min-width: 1200px) {
  .container {
    width: 1024px;
  }
}
.centerElement {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cancelCenterElement {
  position: initial;
  top: initial;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.transition {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
}
.blueButton {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  box-shadow: none;
  background-color: #03A9F4;
  border-radius: 30px;
  padding: 12px 51px;
  border: none;
  color: white;
}
.blueButton:hover {
  background-color: #32B4F0;
}
.blueBorderButton {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 10px 22px;
  border: 2px solid #03A9F4;
  color: #03A9F4;
}
.blueBorderButton:hover {
  background-color: #03A9F4;
  color: white;
}
#slider {
  display: flex;
  justify-content: space-between;
  height: 150px;
}
@media (max-width: 767px) {
  #slider {
    min-height: 90px;
  }
}
#slider .prev-slide,
#slider .next-slide {
  border: none;
  background-color: transparent;
}
@media (max-width: 767px) {
  #slider .prev-slide,
  #slider .next-slide {
    visibility: hidden;
    pointer-events: none;
  }
}
#slider .prev-slide:focus,
#slider .next-slide:focus {
  outline: none;
}
#slider .prev-slide i,
#slider .next-slide i {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  color: #777777;
  font-size: 36px;
}
#slider .prev-slide i:hover,
#slider .next-slide i:hover {
  color: #333333;
}
#slider .prev-slide i:active,
#slider .next-slide i:active {
  color: #03A9F4;
}
#slider .slider-content {
  padding: 0 36px;
}
@media (max-width: 991px) {
  #slider .slider-content {
    padding: 0px;
  }
}
#slider .slider-content .oneSlide {
  position: relative;
}
#slider .slider-content .oneSlide h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 24px;
  position: relative;
  color: #333333;
  line-height: 32px;
}
@media (max-width: 767px) {
  #slider .slider-content .oneSlide h1 {
    font-size: 18px;
    line-height: 28px;
  }
}
#slider .slider-content .oneSlide p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #333333;
}
.disableScroll {
  overflow: hidden;
}
.blurBgWrapper {
  background-color: #383838;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.blurBgWrapper .blurBg {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  opacity: 0.5;
  background-position: center center;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.1);
  filter: blur(20px);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
}
@media (max-width: 991px) {
  .blurBgWrapper .blurBg {
    background-image: none !important;
  }
}
/*animation*/
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.bouncyArrow {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  box-shadow: none;
  border: none;
  animation: bounce 3.5s infinite;
  -webkit-animation: bounce 3.5s infinite;
  -moz-animation: bounce 3.5s infinite;
  -o-animation: bounce 3.5s infinite;
  cursor: pointer;
}
.bouncyArrow i {
  font-size: 36px;
}
.bouncyArrow:hover i {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  color: #03A9F4;
}
/**Global End**/
.video-wrapper {
  position: absolute;
  overflow: hidden;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 767px) {
  .video-wrapper {
    height: 320px;
  }
}
.video-wrapper video {
  position: absolute;
  left: 50%;
  /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */
  height: 100%;
  width: 177.77777778vh;
  /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw;
  /* 100 * 9 / 16 */
}
.hamburger-overlay {
  background-color: black;
  height: 100%;
  z-index: 10;
  opacity: 0;
  display: none;
}
.hamburger-overlay .wrapper {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  text-align: center;
}
.hamburger-overlay .wrapper span {
  margin-bottom: 32px;
}
.hamburger-overlay .wrapper span a {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.hamburger-overlay .wrapper span a:hover {
  color: #03A9F4;
}
/* alternative header style*/
#blackHeader {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  border-bottom: 1px solid #dddddd;
}
#blackHeader.header-open {
  border: none;
}
#blackHeader a {
  color: #777777;
}
#blackHeader a:hover {
  color: #03A9F4;
}
#blackHeader a.blueBorderButton {
  color: #03A9F4;
}
#blackHeader a.blueBorderButton:hover {
  color: white;
}
#blackHeader .hamburger-menu .line {
  background-color: #777777;
}
#blackHeader .hamburger-menu.menu-open .line {
  background-color: white;
}
.header {
  position: absolute;
  height: 88px;
  z-index: 99;
}
@media (max-width: 767px) {
  .header .container {
    padding: 24px;
  }
}
.header .container .logo {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header .container .logo img {
  z-index: 101;
  width: 40px;
}
@media (max-width: 767px) {
  .header .container .logo img {
    width: 22px;
  }
}
.header .container .nav {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header .container .nav .wrapper {
  float: right;
}
.header .container .nav .wrapper span {
  display: inline;
  text-align: right;
  margin-right: 24px;
}
.header .container .nav .wrapper span a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.header .container .nav .wrapper span a:hover {
  color: #03A9F4;
}
.header .container .nav .wrapper span a.blueBorderButton {
  color: #03A9F4;
}
.header .container .nav .wrapper span a.blueBorderButton:hover {
  color: white;
}
.header .container .nav .wrapper span:last-child {
  margin-right: 0px;
}
.header .container .nav .hamburger-menu {
  cursor: pointer;
  padding: 13px 0px;
  width: 24px;
  height: 44px;
  float: right;
}
.header .container .nav .hamburger-menu .line {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  position: relative;
  width: 100%;
  margin-top: 3px;
  height: 2px;
  background-color: white;
  opacity: 1;
}
.header .container .nav .hamburger-menu .line:nth-child(2) {
  right: 0px;
}
.header .container .nav .hamburger-menu .line:nth-child(3) {
  top: 0px;
}
.header .container .nav .hamburger-menu.menu-open .line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header .container .nav .hamburger-menu.menu-open .line:nth-child(2) {
  opacity: 0;
  right: 20px;
}
.header .container .nav .hamburger-menu.menu-open .line:nth-child(3) {
  top: -10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sectionOne {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  z-index: 98;
}
@media (max-width: 991px) {
  .sectionOne {
    height: 640px;
  }
  .sectionOne .container {
    padding: 24px;
  }
  .sectionOne .blueButton {
    position: relative;
    top: -25px;
  }
  .sectionOne .blueButton:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
@media (max-width: 767px) {
  .sectionOne {
    height: 320px;
  }
}
.sectionOne .wrapper {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sectionOne .wrapper h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .sectionOne .wrapper h1 {
    font-size: 24px;
  }
}
.sectionOne .wrapper h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .sectionOne .wrapper h2 {
    font-size: 16px;
    line-height: 24px;
  }
}
.sectionTwo {
  margin-bottom: 24px;
  padding: 24px 24px 43px 24px;
  background-color: white;
}
.sectionTwo .container {
  height: auto;
}
.sectionTwo h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #777777;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 30px;
}
.sectionTwo .wrapper {
  display: flex;
  justify-content: space-around;
  padding: 0 192px;
}
.sectionTwo .wrapper i {
  font-size: 34px;
  color: #cccccc;
}
.sectionThree {
  background-color: white;
}
.sectionThree .container {
  padding: 0 125px;
}
@media (max-width: 767px) {
  .sectionThree .container {
    padding: 48px 24px;
    height: auto;
  }
}
@media (max-width: 991px) {
  .sectionThree .container {
    padding: 48px 24px;
    height: auto;
  }
}
.sectionThree .oneInfo {
  margin-bottom: 80px;
  height: 420px;
}
@media (max-width: 767px) {
  .sectionThree .oneInfo {
    height: auto;
    margin-bottom: 32px;
  }
}
@media (max-width: 991px) {
  .sectionThree .oneInfo {
    height: auto;
    margin-bottom: 32px;
  }
}
.sectionThree .oneInfo img {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .sectionThree .oneInfo img {
    margin-bottom: 12px;
  }
}
.sectionThree .oneInfo .description {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sectionThree .oneInfo .description img {
  width: 48px;
}
@media (max-width: 767px) {
  .sectionThree .oneInfo .description {
    position: initial;
    top: initial;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    padding: 0!important;
    text-align: center;
  }
  .sectionThree .oneInfo .description h1 {
    font-size: 24px;
  }
  .sectionThree .oneInfo .description p {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .sectionThree .oneInfo .description {
    position: initial;
    top: initial;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    padding: 0!important;
    text-align: center;
  }
  .sectionThree .oneInfo .description h1 {
    font-size: 24px;
  }
  .sectionThree .oneInfo .description p {
    font-size: 14px;
    line-height: 20px;
  }
}
.sectionThree .oneInfo.right .description {
  padding-right: 8px;
}
.sectionThree .oneInfo.right .image {
  padding-left: 24px;
}
.sectionThree .oneInfo.left .description {
  padding-left: 8px;
}
.sectionThree .oneInfo.left .image {
  padding-right: 24px;
}
.sectionThree .oneInfo .image {
  height: 100%;
}
.sectionThree .oneInfo .image img {
  max-width: 100%;
}
.sectionThree .oneInfo h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 24px;
  color: #212121;
  line-height: 26px;
}
@media (max-width: 767px) {
  .sectionThree .oneInfo h1 {
    margin-bottom: 16px;
  }
}
.sectionThree .oneInfo p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  line-height: 28px;
}
.sectionFour .sectionA {
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  height: 50%;
  background-position: center center;
}
.sectionFour .container {
  position: relative;
  text-align: center;
  color: #333333;
  padding-top: 64px;
  padding-bottom: 67px;
}
@media (max-width: 767px) {
  .sectionFour .container {
    padding: 48px 0px;
    top: -320px;
  }
}
.sectionFour .container .testimonials {
  margin-bottom: 47px;
}
.sectionFour .container .testimonials h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .sectionFour .container .testimonials h1 {
    font-size: 24px;
    margin-bottom: 48px;
  }
}
.sectionFour .container .demoImgs {
  margin-bottom: 16px;
  padding: 0 72px;
}
@media (max-width: 767px) {
  .sectionFour .container .demoImgs {
    height: auto;
    margin-bottom: 36px;
    padding: 0px;
  }
}
.sectionFour .container .demoImgs img {
  width: 100%;
}
.sectionFour .container .downloadApp h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .sectionFour .container .downloadApp h1 {
    font-size: 24px;
  }
}
.sectionFour .container .downloadApp h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .sectionFour .container .downloadApp h2 {
    font-size: 18px;
  }
}
.sectionFour .container .downloadApp a:nth-child(1) {
  margin-right: 12px;
}
.sectionFour .container .downloadApp a:nth-child(2) {
  margin-left: 12px;
}
.sectionFour .container .downloadApp a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
}
.sectionFour .container .downloadApp a:hover {
  opacity: 0.6;
  text-decoration: none;
}
.sectionFour .container .downloadApp a img {
  width: 25%;
}
@media (max-width: 991px) {
  .sectionFour .container .downloadApp a img {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .sectionFour .container .downloadApp a {
    margin: 0px;
  }
  .sectionFour .container .downloadApp a img {
    width: 50%;
  }
}
.sectionFive {
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  color: white;
  background-position: center center;
}
@media (max-width: 767px) {
  .sectionFive {
    background-image: none !important;
    background-color: #323A45;
  }
}
@media (max-width: 991px) {
  .sectionFive {
    background-image: none !important;
    background-color: #323A45;
  }
}
.sectionFive .container {
  height: auto;
  padding: 64px;
}
@media (max-width: 767px) {
  .sectionFive .container {
    padding: 48px 24px;
    padding-bottom: 0px;
  }
}
.sectionFive .container .wrapper h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .sectionFive .container .wrapper h1 {
    font-size: 24px;
  }
}
.sectionFive .container .wrapper h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 24px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .sectionFive .container .wrapper h2 {
    font-size: 18px;
  }
}
.sectionFive .container .wrapper p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 28px;
}
@media (max-width: 767px) {
  .sectionFive .container .wrapper p {
    font-size: 14px;
    text-align: center;
    line-height: 24px;
  }
}
.sectionFive .container .wrapper p a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  text-decoration: underline;
  color: white;
}
.sectionFive .container .wrapper p a:hover {
  color: #03A9F4;
}
.footer {
  background-color: #323A45;
}
.footer .container {
  height: auto;
}
.footer .normalFooter {
  height: 80px;
}
.footer .normalFooter .icon {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.footer .normalFooter .icon a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  font-size: 24px;
  color: white;
  text-decoration: none;
  margin-right: 24px;
}
.footer .normalFooter .icon a:hover {
  color: #03A9F4;
}
.footer .normalFooter .links {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  color: white;
}
.footer .normalFooter .links a {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
}
.footer .normalFooter .links a:hover {
  color: #03A9F4;
}
.footer .normalFooter .links p {
  padding: 0 8px;
}
.footer .innerFooter {
  padding: 48px 0;
}
.footer .innerFooter .icon {
  margin-bottom: 20px;
  text-align: center;
}
.footer .innerFooter .icon a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  line-height: 24px;
  font-size: 24px;
  color: white;
  text-decoration: none;
}
.footer .innerFooter .icon a:hover {
  color: #03A9F4;
}
.footer .innerFooter .icon a:nth-child(1) {
  margin-right: 24px;
}
.footer .innerFooter .links {
  color: white;
  text-align: center;
}
.footer .innerFooter .links a {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 700;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  color: white;
  text-decoration: none;
}
.footer .innerFooter .links a:hover {
  color: #03A9F4;
}
.footer .innerFooter .links p {
  padding: 0 8px;
}
.privacyTerms {
  margin-top: 88px;
}
.privacyTerms .container {
  padding: 96px 80px;
  height: auto;
}
@media (max-width: 991px) {
  .privacyTerms .container {
    padding: 16px;
  }
}
.privacyTerms .container ol > li {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #777777;
  margin-bottom: 40px;
}
.privacyTerms .container ol > li:last-child {
  margin-bottom: 0;
}
.privacyTerms .container ul {
  list-style-type: disc;
}
.privacyTerms .container ul li {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  margin-bottom: 8px;
  line-height: 28px;
}
.privacyTerms .container h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: 300;
  color: #212121;
  margin-bottom: 40px;
}
.privacyTerms .container h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 300;
  color: #333333;
  margin-bottom: 40px;
}
.privacyTerms .container h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #777777;
  margin-bottom: 16px;
}
.privacyTerms .container p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  margin-bottom: 24px;
  line-height: 28px;
}
.privacyTerms .container p:last-child {
  margin-bottom: 0px;
}
.verseCarousel {
  overflow-x: hidden;
  height: 100%;
}
@media (max-width: 991px) {
  .verseCarousel {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    overflow: hidden;
  }
}
.verseCarousel .container {
  padding: 0 16px;
}
.verseCarousel .container .socialButtons {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  margin-top: 50px;
  padding: 0 180px;
  display: flex;
  justify-content: space-between;
}
.verseCarousel .container .socialButtons a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
  font-size: 42px;
  color: white;
}
.verseCarousel .container .socialButtons a:hover {
  color: #03A9F4;
  text-decoration: none;
}
@media (max-width: 1199px) {
  .verseCarousel .container .socialButtons {
    margin-top: 24px;
  }
}
@media (max-width: 991px) {
  .verseCarousel .container .socialButtons {
    margin-top: 0px;
    padding: 0 40px;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .verseCarousel .container .socialButtons a {
    font-size: 36px;
  }
  .verseCarousel .container .socialButtons .downloadBtn #download {
    width: 100%;
  }
  .verseCarousel .container .socialButtons .icons {
    margin-top: 16px;
    display: flex;
    justify-content: space-around;
  }
}
@media (max-width: 767px) {
  .verseCarousel .container .socialButtons {
    padding: 0px;
  }
}
.verseCarousel .container .swiper-container {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: visible;
  height: 500px;
}
@media (max-height: 700px) and (min-width: 1200px) {
  .verseCarousel .container .swiper-container {
    height: 400px;
  }
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container {
    position: initial;
    top: initial;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    height: auto;
    position: relative;
    top: 88px;
  }
}
.verseCarousel .container .swiper-container .swiper-slide {
  padding: 0 40px;
}
@media (max-width: 767px) {
  .verseCarousel .container .swiper-container .swiper-slide {
    padding: 0px;
  }
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer {
  background-color: white;
  display: table;
  width: 100%;
  height: 420px;
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer > div {
  display: table-cell;
  vertical-align: top;
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer > div {
    display: block;
  }
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer {
    display: initial;
  }
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseImgWrapper img {
  height: 100%;
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseImgWrapper img {
    width: 100%;
    height: auto;
  }
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper {
  background-color: white;
  height: 100%;
  padding: 32px 16px 24px 24px;
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper {
    height: auto;
  }
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper .innerWrapper {
  height: 100%;
  overflow: auto;
  color: #777777;
  max-height: 364px;
}
@media (max-width: 991px) {
  .verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper .innerWrapper {
    overflow: scroll;
    height: auto;
  }
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper .innerWrapper h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 32px;
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper .innerWrapper h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 32px;
  text-align: right;
  text-transform: uppercase;
}
.verseCarousel .container .swiper-container .swiper-slide .oneVerseContainer .verseSermonWrapper .innerWrapper p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.verseCarousel .container .swiper-container .swiper-button-prev,
.verseCarousel .container .swiper-container .swiper-button-next {
  background-image: none;
  width: auto;
  height: auto;
}
.verseCarousel .container .swiper-container .swiper-button-prev.swiper-button-disabled,
.verseCarousel .container .swiper-container .swiper-button-next.swiper-button-disabled {
  pointer-events: none;
}
.verseCarousel .container .swiper-container .swiper-button-prev i,
.verseCarousel .container .swiper-container .swiper-button-next i {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  color: white;
  font-size: 36px;
}
.verseCarousel .container .swiper-container .swiper-button-prev i:hover,
.verseCarousel .container .swiper-container .swiper-button-next i:hover {
  color: #03A9F4;
}
.webBanner {
  background-color: #007AFF;
  text-align: center;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 16px;
  line-height: 19px;
  padding: 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
