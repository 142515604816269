@import "variables.less";

//Color Variables Declaration Begin----------
@theme-darkgrey     :#212121;
@theme-grey         :#333333;
@theme-lightgrey    :#777777;
@theme-lightestgrey :#999999;
@theme-iconlightgrey:#cccccc;
@theme-divdergrey   :#dddddd;
@theme-blue         :#03A9F4;
@theme-darkblue     :#323A45;

//Color Variables Declaration End----------

//Font Method Declaration Begin-------------
// @font-option-Raleway : 'Raleway', sans-serif;;
@font-option-OpenSans       : 'Open Sans', sans-serif;
@font-option-Roboto         : 'Roboto', sans-serif;
@font-option-RobotoCondensed: 'Roboto Condensed', sans-serif;

// <link href='https://fonts.googleapis.com/css?family=Roboto:300,700,400' rel='stylesheet' type='text/css'>

.apply-font-OpenSans-Italic(@size) 
{
	font-family: @font-option-OpenSans;
	font-size  : @size;
	font-style : italic;
	font-weight: 300;
}

.apply-font-Roboto-Light(@size) 
{
	font-family: @font-option-Roboto;
	font-size  : @size;
	font-weight: 300;
}

.apply-font-Roboto-Regular(@size) 
{
	font-family: @font-option-Roboto;
	font-size  : @size;
	font-weight: 400;
}

.apply-font-Roboto-Bold(@size) 
{
	font-family: @font-option-RobotoCondensed;
	font-size  : @size;
	font-weight: 700;
}

//Font Method Declaration End-------------

/**Global**/
body, html {
	height                : 100%;
	width                 : 100%;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing   : antialiased;
	-o-font-smoothing     : antialiased;
	font-smoothing        : antialiased;
}

h1,h2,h3,p{
	margin: 0px;
}

.paddingZero{
  padding: 0;
}

.container{
	padding: 0 32px;
	height : 100%;

	@media (min-width: @screen-lg-min){
      width: 1024px;
    }
}

.centerElement {
	position         : relative;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform    : translateY(-50%);
	transform        : translateY(-50%);
}

.cancelCenterElement {
	position         : initial;
	top              : initial;
	-webkit-transform: none;
	-ms-transform    : none;
	transform        : none;
}

.transition{
	-o-transition     :.5s;
	-ms-transition    :.5s;
	-moz-transition   :.5s;
	-webkit-transition:.5s;
	transition        :.5s;
}

.blueButton{
	.transition;
	.apply-font-Roboto-Bold(16px);
	letter-spacing  : 1px;
	box-shadow      : none;
	background-color: @theme-blue;
	border-radius   : 30px;
	padding         : 12px 51px;
	// border       : 2px solid @theme-blue;
	border          : none;
	color           : white;

	&:hover{
		background-color: #32B4F0;
		// color           : @theme-blue;
	}
}

.blueBorderButton{
	.transition;
	.apply-font-Roboto-Bold(14px);
	border          : 2px solid @theme-blue;
	box-shadow      : none;
	background-color: transparent;
	border-radius   : 30px;
	padding         : 10px 22px;
	border          : 2px solid @theme-blue;
	color           : @theme-blue;

	&:hover{
		background-color: @theme-blue;
		color           : white;
	}
}

#slider{
	display        : flex;
	justify-content: space-between;
	height         : 150px;

	@media (max-width: @screen-xs-max){
        min-height: 90px;
    }

	.prev-slide,.next-slide{
		border          : none;
		background-color:transparent;

		@media (max-width: @screen-xs-max){
	     	visibility    : hidden;
			pointer-events: none;
	    }
		
		&:focus{
			outline: none;
		}

		i{
			.transition;
			color    : @theme-lightgrey;
			font-size: 36px;
			&:hover{
				color: @theme-grey;
			}
			&:active{
				color: @theme-blue;
			}
		}
	}

    .slider-content{
    	padding: 0 36px;
    	@media (max-width: @screen-sm-max){
			padding: 0px;
	    }

    	.oneSlide{
    		position: relative;
    		h1{
				.apply-font-OpenSans-Italic(24px);
				margin-bottom: 24px;
				position     : relative;
				color        : @theme-grey;
				line-height  : 32px;
				@media (max-width: @screen-xs-max){
					font-size  : 18px;
					line-height: 28px;
					line-height  : 28px;
			    }

			}
			p{
				.apply-font-Roboto-Regular(16px);
				position: relative;
				color   : @theme-grey;
			}
    	}
    }
}

.disableScroll{
	overflow: hidden;
}

.blurBgWrapper{
	background-color   : #383838;//default bg color

	position: fixed;
	width   : 100%;
	height  : 100%;
	overflow: hidden;

	.blurBg{
		.transition;
		opacity             : 0.5;
		background-position : center center;
		// background-repeat: no-repeat;
		height              : 100%;
		width               : 100%;
		background-repeat   : no-repeat;
		background-size     : cover;
		transform           : scale(1.1);//scale up abit so that blurry edges wont show
		
		filter              : blur(20px);
		-webkit-filter      : blur(20px);
		-moz-filter         : blur(20px);
		-o-filter           : blur(20px);
		-ms-filter          : blur(20px);

		@media (max-width: @screen-sm-max){
			background-image: none !important;
	    }
	}
}

/*animation*/
@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
	40% {-webkit-transform: translateY(-30px);}
	60% {-webkit-transform: translateY(-15px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-30px);}
	60% {-moz-transform: translateY(-15px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-30px);}
	60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

.bouncyArrow{
	.transition;
	position         : absolute;
	bottom           : 0;
	background-color : transparent;
	box-shadow       : none;
	border           : none;
	animation        : bounce 3.5s infinite;
	-webkit-animation: bounce 3.5s infinite;
	-moz-animation   : bounce 3.5s infinite;
	-o-animation     : bounce 3.5s infinite;
	cursor           : pointer;
	i{
		font-size: 36px;
	}

	&:hover{
		i{
			.transition;
			color: @theme-blue;
		}
	}
}
/**Global End**/

.video-wrapper{
	position               : absolute;
	overflow               : hidden;
	// height                 : 100%;
	background-size        : cover;
	-o-background-size     : cover;
	-moz-background-size   : cover;
	-webkit-background-size: cover;
	background-repeat      : no-repeat;
	background-position    : center center;

	@media (max-width: @screen-xs-max){
		height: 320px;
    }
	video{
		position  : absolute;
		left      : 50%; /* % of surrounding element */
		top       : 50%;
		transform : translate(-50%, -50%); /* % of current element */

		height    : 100%;
		width     : 177.77777778vh; /* 100 * 16 / 9 */
		min-width : 100%;
		min-height: 56.25vw; /* 100 * 9 / 16 */
	} 
}

.hamburger-overlay{
	background-color: black;
	height          : 100%;
	z-index         : 10;
	opacity         : 0;
	display         : none;

	.wrapper{
		.centerElement;
		display       : flex;
		flex-direction: column;
		text-align    : center;
		span{
			margin-bottom: 32px;
			a{
				.apply-font-Roboto-Light(24px);
				.transition;
				cursor         : pointer;
				color          : white;
				text-decoration: none;

				&:hover{
					color: @theme-blue;
				}
			}
		}
	}
}

/* alternative header style*/
 #blackHeader{
 	.transition;
 	border-bottom: 1px solid @theme-divdergrey;

 	&.header-open{
		border          : none;
 	}

	a{
		color: @theme-lightgrey;

		&:hover{
			color: @theme-blue;
		}

		&.blueBorderButton{
    		color: @theme-blue;
    		&:hover{
				color : white;
			}
    	}
	}

	.hamburger-menu{
		.line{
			background-color: @theme-lightgrey;
		}
		&.menu-open{
			.line{
				background-color: white;
			}
		}
	}
}

.header{
	position: absolute;
	height  : 88px;
	z-index : 99;

	.container{
		@media (max-width: @screen-xs-max){
			padding: 24px;
	    }

		.logo{
			.centerElement;
			img{
				z-index: 101;
				width: 40px;

				@media (max-width: @screen-xs-max){
					width: 22px;
			    }
			}
		}
	    .nav{
	    	.centerElement;
	    	.wrapper{
	    		float: right;
	    		span{
	    			display: inline;
		    		text-align: right;
	    		    margin-right: 24px;
		    		a{
				    	.transition;
				    	.apply-font-Roboto-Bold(14px);
						color          : white;
						text-decoration: none;
						cursor         : pointer;

				    	&:hover{
							color : @theme-blue;
						}

				    	&.blueBorderButton{
				    		color: @theme-blue;
				    		&:hover{
								color : white;
							}
				    	}
				    }
		    	}
		    	span:last-child{
		    		margin-right: 0px;
		    	}
	    	}

	    	.hamburger-menu{
				cursor : pointer;
				padding: 13px 0px;
				width  : 24px;
				height : 44px;
				float  : right;

				.line{
					.transition;
					position        : relative;
					width           : 100%;
					margin-top      : 3px;
					height          : 2px;
					background-color: white;
					opacity         : 1;
				}

				.line:nth-child(2)
				{
					right: 0px;
				}

				.line:nth-child(3)
				{
					top: 0px;
				}
				
				&.menu-open{
					.line:nth-child(1)
					{
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}

					.line:nth-child(2)
					{
						opacity: 0;
						right  : 20px;
					}

					.line:nth-child(3)
					{
					    top: -10px;
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}

			}
	    }
	}
}

.sectionOne{
	height          : 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color           : white;
	text-align      : center;
	z-index         : 98;

	@media (max-width: @screen-sm-max){
		height: 640px;

		.container{
	      padding: 24px;
	    }

		.blueButton{
			position: relative;
			top     : -25px;
			&:hover{
				background-color: rgba(255, 255, 255, 0.7); 
			}
	    }
    }

	@media (max-width: @screen-xs-max){
		height: 320px;
    }

	.wrapper{
		.centerElement;
		h1{
			.apply-font-Roboto-Light(40px);
			margin-bottom: 16px;

			@media (max-width: @screen-xs-max){
				font-size: 24px;
		    }
		}

		h2{
			.apply-font-OpenSans-Italic(24px); 
			margin-bottom: 32px;

			@media (max-width: @screen-xs-max){
				font-size: 16px;
			    line-height: 24px;
		    }
		}
	}
}

.sectionTwo{
	margin-bottom   :24px;
	padding         : 24px 24px 43px 24px;
	background-color: white;

	.container{
		height: auto;
	}

	h1{
		.apply-font-Roboto-Bold(16px);
		color         : @theme-lightgrey;
		text-align    : center;
		letter-spacing: 1px;
		margin-bottom : 30px;
	}
	.wrapper{
		display        : flex;
		justify-content: space-around;
		padding        : 0 192px;

		i{
			font-size: 34px;
			color    : @theme-iconlightgrey;
		}
	}
}

.sectionThree{
	background-color: white;

	.container{
		padding: 0 125px;

		@media (max-width: @screen-xs-max){
			padding: 48px 24px;
			height : auto;
	    }

	    @media (max-width: @screen-sm-max){
			padding: 48px 24px;
			height : auto;
	    }
	}

	.oneInfo{
		margin-bottom: 80px;
		height       : 420px;

		@media (max-width: @screen-xs-max){
			height       : auto;
			margin-bottom: 32px;
	    }

	    @media (max-width: @screen-sm-max){
			height       : auto;
			margin-bottom: 32px;
	    }

		img{
		    margin-bottom: 16px;

		    @media (max-width: @screen-xs-max){
		    	margin-bottom: 12px;
		    }
		}

		.description{
			.centerElement;

			img{
				width: 48px;
			}

			@media (max-width: @screen-xs-max){
				.cancelCenterElement;
				padding   : 0!important;
				text-align: center;

				h1{
					font-size: 24px;
				}
				p{
					font-size: 14px;
				}
		    }

		    @media (max-width: @screen-sm-max){
				.cancelCenterElement;
				padding   : 0!important;
				text-align: center;

				h1{
					font-size: 24px;
				}
				p{
					font-size: 14px;
					line-height  : 20px;
				}
		    }
		}

		&.right{
			.description{
				padding-right: 8px;
			}
			.image{
				padding-left: 24px;
			}
		}
		&.left{
			.description{
				padding-left: 8px;
			}
			.image{
				padding-right: 24px;
			}
		}

		.image{
			height: 100%;

			img{
				max-width: 100%;
			}
		}

		h1{
			.apply-font-Roboto-Light(36px);
			margin-bottom: 24px;
			color        : @theme-darkgrey;
			line-height  : 26px;

			@media (max-width: @screen-xs-max){
				margin-bottom: 16px;
		    }
		}

		p{
			.apply-font-Roboto-Regular(16px);
			color      : @theme-lightgrey;
			line-height: 28px;
		}
	}
}

.sectionFour{

	.sectionA{
		background-size        : cover;
		-o-background-size     : cover;
		-moz-background-size   : cover;
		-webkit-background-size: cover;
		background-repeat      : no-repeat;
		height                 : 50%;
		background-position    : center center;
	}

	.container{
		// top           : -400px;
		position      : relative;
		text-align    : center;
		color         : @theme-grey;
		padding-top   : 64px;
		padding-bottom: 67px; 

		@media (max-width: @screen-xs-max){
		    padding: 48px 0px;
			top    : -320px;
	    }

		.testimonials{
		    margin-bottom: 47px;

			h1{
				.apply-font-Roboto-Light(36px);
				margin-bottom: 40px;

				@media (max-width: @screen-xs-max){
			        font-size    : 24px;
					margin-bottom: 48px;
			    }
			}
			
		}
		.demoImgs{
			margin-bottom: 16px;
			padding      : 0 72px;

			@media (max-width: @screen-xs-max){
				height       : auto;
				margin-bottom: 36px;
				padding      : 0px;
			}

			img{
				width  : 100%;
			}
		}
		.downloadApp{
			h1{
				.apply-font-Roboto-Light(36px);
				margin-bottom: 16px;

				@media (max-width: @screen-xs-max){
					font-size: 24px;
				}
			}

			h2{
				.apply-font-OpenSans-Italic(24px); 
				margin-bottom: 40px;

				@media (max-width: @screen-xs-max){
					font-size: 18px;
				}
			}

			a:nth-child(1){
				margin-right: 12px;
			}

			a:nth-child(2){
				margin-left: 12px;
			}

			a{
				.transition;
				&:hover{
					opacity: 0.6;
					text-decoration : none;
				}

				img{
					width: 25%;
				}
			}

			@media (max-width: @screen-sm-max){
				a{
					img{
						width: 30%;
					}
				}
		    }

			@media (max-width: @screen-xs-max){
				a{
					margin: 0px;
					img{
						width: 50%;
					}
				}
		    }
		}
	}
}

.sectionFive{
	background-size        : cover;
	-o-background-size     : cover;
	-moz-background-size   : cover;
	-webkit-background-size: cover;
	background-repeat      : no-repeat;
	background-color       : rgba(0, 0, 0, 0.5);
	text-align             : center;
	color                  : white;
	background-position    : center center;

	@media (max-width: @screen-xs-max){
		background-image: none !important;
		background-color: @theme-darkblue;
    }

    @media (max-width: @screen-sm-max){
		background-image: none !important;
		background-color: @theme-darkblue;
    }

	.container{
		height: auto;
		padding: 64px;

		@media (max-width: @screen-xs-max){
			padding       : 48px 24px;
			padding-bottom: 0px
	    }

		.wrapper{
			// .centerElement;

			h1{
				.apply-font-Roboto-Light(36px);
				margin-bottom: 24px;
				@media (max-width: @screen-xs-max){
					font-size: 24px;
			    }
			}

			h2{
				.apply-font-OpenSans-Italic(24px); 
				margin-bottom: 24px;
				line-height  : 24px;
				@media (max-width: @screen-xs-max){
					font-size: 18px;
			    }
			}

			p{
				.apply-font-Roboto-Regular(16px);
				text-align : left;
				line-height: 28px;
				@media (max-width: @screen-xs-max){
					font-size  : 14px;
					text-align : center;
					line-height: 24px;
			    }

			    a{
			    	.transition;
					text-decoration: underline;
					color          : white;

			    	&:hover{
			    		color:@theme-blue;
			    	}
			    }
			}
		}
	}
}

.footer{
	background-color: @theme-darkblue;

	.container{
		height: auto;
	}

	.normalFooter{
		height          : 80px;

		.icon{
			.centerElement;

			a{
				.transition;
				font-size      : 24px;
				color          : white;
				text-decoration: none;
				margin-right   : 24px;
				&:hover{
					color:@theme-blue;
				}
			}
		}
		.links{
			.centerElement;
			display        : flex;
			justify-content: flex-end;
			color          : white;
			
			a{
				.apply-font-Roboto-Bold(14px);
				.transition;
				color          : white;
				text-decoration: none;
				letter-spacing : 1px;
				&:hover{
					color:@theme-blue;
				}
			}
			p{
			    padding: 0 8px;
			}
		}
	}

	.innerFooter{

		padding: 48px 0;

		.icon{
			margin-bottom: 20px;
		    text-align: center;

			a{
				.transition;
				line-height    : 24px;
				font-size      : 24px;
				color          : white;
				text-decoration: none;
					&:hover{
						color:@theme-blue;
					}
			}

			a:nth-child(1)
			{
				margin-right : 24px;
			}
		}

		.links{
			color     : white;
			text-align: center;

			a{
				.apply-font-Roboto-Bold(14px);
				.transition;
				color          : white;
				text-decoration: none;
				&:hover{
					color:@theme-blue;
				}
			}
			p{
				padding: 0 8px;
			}
		}
	}
}

.privacyTerms{
	margin-top: 88px;

	.container{
		padding: 96px 80px;
		height : auto;

		@media (max-width: @screen-sm-max){
			padding: 16px;
	    }

		ol {

			&>li {
				.apply-font-Roboto-Bold(16px);
				color: @theme-lightgrey;
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		ul {
			list-style-type: disc;

			li {
				.apply-font-Roboto-Regular(16px);
				color        : @theme-lightgrey;
				margin-bottom: 8px;
				line-height  : 28px;
			}
		}

		h1{
			.apply-font-Roboto-Light(36px);
			color        : @theme-darkgrey;
			margin-bottom: 40px;
		}

		h2{
			.apply-font-OpenSans-Italic(24px);
			color        : @theme-grey;
			margin-bottom: 40px;
		}

		h3{
			.apply-font-Roboto-Bold(16px);
			color         : @theme-lightgrey;
			// letter-spacing: 1px;
			margin-bottom : 16px;
			// text-transform: uppercase;
		}

		p{
			.apply-font-Roboto-Regular(16px);
			color        : @theme-lightgrey;
			margin-bottom: 24px;
			line-height  : 28px;
		}

		p:last-child{
			margin-bottom: 0px;
		}

	}
}

//verse of the day carousel
.verseCarousel{
	overflow-x: hidden;
	height: 100%;

	@media (max-width: @screen-sm-max){
		.transition;
		overflow: hidden;
    }

	.container{
		padding: 0 16px;

		.socialButtons{
			.transition;
			margin-top     : 50px;
			padding        : 0 180px;
			display        : flex;
			justify-content: space-between;

			a{
				.transition;
				cursor   : pointer;
				font-size: 42px;
				color    : white;
				&:hover{
					color          : @theme-blue;
					text-decoration: none;
				}
			}

			@media (max-width: @screen-md-max){
				margin-top: 24px;
			}

			@media (max-width: @screen-sm-max){
				margin-top    : 0px;
				padding       : 0 40px;
				position      : absolute;
				display       : flex;
				flex-direction: column;
				width         : 100%;

				a{
					font-size: 36px;
				}

				.downloadBtn{
					#download{
						width: 100%;
					}
				}

				.icons{
					margin-top     : 16px;
					display        : flex;
					justify-content:space-around;
				}
		    }

		    @media (max-width: @screen-xs-max){
				padding       : 0px;
		    }
		}
		.swiper-container{
			.centerElement;
			overflow: visible;
			height  : 500px;

			@media (max-height: 700px) and (min-width: @screen-lg-min) {
			    height  : 400px;
			}

			@media (max-width: @screen-sm-max){
				.cancelCenterElement;
				height  : auto;
				position: relative;
				top     : 88px;
		    }

			.swiper-slide{
				padding: 0 40px;

				@media (max-width: @screen-xs-max){
					padding       : 0px;
					// pointer-events: none;
			    }

				.oneVerseContainer{
					background-color: white;
					display: table;
					width: 100%;
					height: 420px;

					>div {
						display: table-cell;
						//display: inline-block;
						vertical-align: top;
						  
						@media (max-width: @screen-sm-max){
							display: block;
						}
					}

					@media (max-width: @screen-sm-max){
				        display : initial;
				    }

					.verseImgWrapper
					{
						img{
							// width: 500px;
							height: 100%;
							@media (max-width: @screen-sm-max){
								width : 100%;
								height: auto;
						    }
						}
					}

					.verseSermonWrapper{
						background-color: white;
						height  : 100%;
						padding : 32px 16px 24px 24px;
						// width: 50%!important;

						@media (max-width: @screen-sm-max){
							height  : auto;
					    }

						.innerWrapper{
							height  : 100%;
							overflow: auto;
							color   : @theme-lightgrey;
							max-height: 364px;

							@media (max-width: @screen-sm-max){
								overflow: scroll;
								height  : auto;
						    }

							h2{	
								.apply-font-OpenSans-Italic(18px);
								line-height  : 28px;
								margin-bottom: 32px;
							}

							h3{
								.apply-font-Roboto-Bold(16px);
								letter-spacing: 1px;
								margin-bottom : 32px;
								text-align    : right;
								text-transform: uppercase;
							}

							p{
								.apply-font-Roboto-Regular(16px);
								line-height: 28px;
							}
						}
					}
				}
			}

			.swiper-button-prev,.swiper-button-next{
				background-image: none;
				width           : auto;
				height          : auto;

				&.swiper-button-disabled{
				    pointer-events: none;
				}

				i{
					.transition;
					color    : white;
					font-size: 36px;
					&:hover{
						color: @theme-blue;
					}
				}
			}	
		}
	}
}

.webBanner {
	background-color: #007AFF;
    text-align: center;
    color: white;
    font-family: Roboto,sans-serif;
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 16px;
    line-height: 19px;
    padding: 18px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}
